import React from 'react';
import { graphql } from 'gatsby';

import { container, header, icon, title, text } from './closed.module.scss';

import IconSent from '../../../assets/images/svg/icon_completed.svg';

import { IPage } from '../../../models/page.model';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import WinterLayout from '../../../layouts/winter-layout';

interface IWinterContestPage {
    readonly data: {
        page: IPage | null;
    };
}

const Closed: React.FC<IWinterContestPage> = ({ data }) => {
    const { page } = data;
    const { t } = useI18next();

    return (
        <WinterLayout pageType={'closed'} page={page} className={container}>
            <div className={header}>
                <IconSent className={icon} />
                <h1 className={title}>{t('winter.contest.closed.title.1')}</h1>
                <p className={text}>{t('winter.contest.closed.title.2')}</p>
            </div>
        </WinterLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Closed;
